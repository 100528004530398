import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I'm often asked where I find a the events that I attend to socialize with other nomads. Previously there was no central source. As somebody who has greatly benefits from the amazing community at large I'm happy to compile a list.`}</p>
    <p>{`I've started to compile a source of events for others. While this is currently a work in progress, and a better user experience will follow I thought it would be better to provide something now. `}</p>
    <p>{`Please contact me using any of the methods on the top right of this page if you have any suggestions/reports/etc. `}</p>
    <p>{`I'd appreciate if you would share this resource to your friends. I also welcome constructive feedback as I work to improve this service.`}</p>
    <p>{`-Tom & Athena`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      